<template>
  <v-dialog
      v-bind="$attrs"
      class="modal rounded-xl ma-auto"
      :max-width="$vuetify.breakpoint.smAndDown ? '95%': '40%'"
      :min-height="$vuetify.breakpoint.smAndDown ? '350': '100%'"
      style="border: 1px solid red !important;"
      v-on="$listeners"
  >
    <v-card
        class="rounded-xl py-7 mx-auto"
        width="100%"
    >
      <p
          class="modal__title text-center pa-0 ma-0 mx-15 unselect"
      >
        Exportar
      </p>
      <v-card-text
          class="mb-0 mt-10 d-flex flex-column align-center"
      >
        <v-form ref="exportForm">
          <label for="exportFrom">Exportar desde:</label>
          <v-menu
              id="exportFrom"
              v-model="showExportFromMenu"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  class="mt-1"
                  v-model="exportFrom"
                  :error-messages="errors.exportFrom"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  solo
                  flat
                  outlined
                  dense
                  @focus="errors.exportFrom = ''"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="exportFrom"
                @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>

          <label for="exportTo">Hasta:</label>
          <v-menu
              id="exportTo"
              v-model="showExportToMenu"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  class="mt-1"
                  v-model="exportToFormatted"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  solo
                  flat
                  outlined
                  dense
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="exportTo"
                @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center pa-5 pb-0">
        <v-btn
            class="btn mr-1"
            color="#009EFB"
            style="color: #ffffff;"
            depressed
            @click="exportarSimulacro(simulacroId)"
        >
          Exportar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  data: () => ({
    isVisible: false,
    counter: 0,
    showExportFromMenu: false,
    showExportToMenu: false,
    exportFrom: null,
    exportTo: null,
    errors: {
      exportFrom: ''
    }
  }),
  props: {
    simulacroId: Number
  },
  computed: {
    exportFromFormatted() {
      return this.formatDate(this.exportFrom)
    },
    exportToFormatted() {
      return this.formatDate(this.exportTo)
    },
  },
  methods: {
    async exportarSimulacro(simulacroId) {
      if (this.validateExportForm()) {
        const payload = {
          simulacroId,
          dateFrom: this.exportFrom,
          dateTo: this.exportTo,
        }
        this.$emit('export', payload)
        this.exportFrom = null
        this.exportTo = null
      }
    },
    validateExportForm () {
      let validated = true
      const today = moment()
      const momentExportFrom = moment(this.exportFrom)
      if (momentExportFrom > today) {
        validated = false
        this.errors.exportFrom = 'La fecha de inicio no puede ser mayor a la fecha de hoy.'
      }
      return validated
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
  }
}
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 1s;
}

.bounce-leave-active {
  animation: bounce-in 1s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.modal {
  &__list {
    font-size: 14px;
    line-height: 16px;
    color: #647485;
    border-bottom: 0.5px solid #D3D3D3;
    width: 90% !important;
  }

  &__border {
    border-radius: 40px !important;
  }

  &__title {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #455A64;
  }

  &__btn {
    text-transform: none !important;
    background: #FFF !important;
    border: 1px solid #707070 !important;
    font-size: 16px !important;
    color: #999999;
    box-shadow: none !important;
    font-weight: normal !important;

    &--cancel {
      background: #FFFFFF;
      color: #000000;
    }

    &--delete {
      background: #009EFB !important;
      color: #FFF;
    }
  }
}

.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
