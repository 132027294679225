var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-7"},[_c('v-card-text',{staticClass:"pa-5"},[_c('div',{staticClass:"d-sm-flex flex-column align-center justify-space-between"},[_c('div',{staticClass:"ml-3 d-flex justify-space-between w-100"},[_c('div',[_c('h2',{staticClass:"font-weight-regular title"},[_vm._v(" Registrados ")]),_c('h6',{staticClass:"body-2 mb-1 subtitle-2 font-weight-light"},[_vm._v(" Datos de registrados ")])])]),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headerArray,"items":_vm.simulacros,"options":_vm.options,"page":_vm.page,"server-items-length":_vm.totalCounts,"items-per-page":_vm.pageSize,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.nombre)+" ")])])])]}},{key:"item.respuestas_usuarios",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mx-auto"},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.respuestas_usuarios)+" ")])])])]}},{key:"item.fecha_apertura",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(_vm.getDateFormat(item.fecha_apertura) || '-')+" ")])])])]}},{key:"item.fecha_cierre",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(_vm.getDateFormat(item.fecha_cierre) || '-')+" ")])])])]}},{key:"item.duracion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.duracion)+" minutos ")])])])]}},{key:"item.cantidad_preguntas",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-auto",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.cantidad_preguntas)+" ")])])])]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around",staticStyle:{"cursor":"pointer"}},[_c('v-btn',{staticClass:"download-button",attrs:{"color":"#FFF"},on:{"click":function($event){return _vm.selectSimulacro(item.id)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}},{key:"item.accion-2",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around",staticStyle:{"cursor":"pointer"}},[_c('v-btn',{staticClass:"download-button",attrs:{"color":"#FFF"},on:{"click":function($event){return _vm.exportStudentsAnwerBySimulacro(item.id)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}},{key:"footer",fn:function(slotProps){return [_c('div',{staticClass:"pagination d-flex justify-space-between ml-auto mr-0 mt-5"},[_c('p',{staticClass:"ma-auto"},[_vm._v(_vm._s(slotProps.props.pagination.page)+" de "+_vm._s(slotProps.props.pagination.pageCount))]),_c('div',[_c('v-btn',{attrs:{"icon":"","disabled":slotProps.props.pagination.page === 1},on:{"click":function($event){_vm.page--}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":slotProps.props.pagination.page === slotProps.props.pagination.pageCount},on:{"click":function($event){_vm.page++}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)])]}}])})],1),_c('exportar-modal',{attrs:{"simulacro-id":_vm.simulacroSelectedId},on:{"export":function($event){return _vm.exportSimulacro($event)}},model:{value:(_vm.showExportarModal),callback:function ($$v) {_vm.showExportarModal=$$v},expression:"showExportarModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }