<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5">
      <div class="d-sm-flex flex-column align-center justify-space-between">
        <div class="ml-3 d-flex justify-space-between w-100">
          <div>
            <h2 class="font-weight-regular title">
              Registrados
            </h2>
            <h6 class="body-2 mb-1 subtitle-2 font-weight-light">
              Datos de registrados
            </h6>
          </div>
        </div>
        <v-spacer></v-spacer>
      </div>
      <!-- Table -->
      <v-data-table
          :headers="headerArray"
          :items="simulacros"
          :options.sync="options"
          :page.sync="page"
          :server-items-length="totalCounts"
          :items-per-page="pageSize"
          :loading="loading"
          hide-default-footer
      >
        <template v-slot:item.nombre="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ item.nombre }}
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:item.respuestas_usuarios="{ item }">
          <div class="mx-auto">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ item.respuestas_usuarios }}
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:item.fecha_apertura="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ getDateFormat(item.fecha_apertura) || '-' }}
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:item.fecha_cierre="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ getDateFormat(item.fecha_cierre) || '-' }}
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:item.duracion="{ item }">
          <div class="d-flex" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ item.duracion }} minutos
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:item.cantidad_preguntas="{ item }">
          <div class="ma-auto" style="cursor: pointer;">
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ item.cantidad_preguntas }}
              </h4>
            </div>
          </div>
        </template>
        <template v-slot:item.accion="{ item }">
          <div class="d-flex justify-space-around" style="cursor: pointer;">
            <v-btn
                color="#FFF"
                class="download-button"
                @click="selectSimulacro(item.id)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.accion-2="{ item }">
          <div class="d-flex justify-space-around" style="cursor: pointer;">
            <v-btn
                color="#FFF"
                class="download-button"
                @click="exportStudentsAnwerBySimulacro(item.id)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:footer="slotProps">
          <div class="pagination d-flex justify-space-between ml-auto mr-0 mt-5">
            <p class="ma-auto">{{ slotProps.props.pagination.page }} de {{ slotProps.props.pagination.pageCount }}</p>
            <div>
              <v-btn icon @click="page--" :disabled="slotProps.props.pagination.page === 1">
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn icon @click="page++"
                     :disabled="slotProps.props.pagination.page === slotProps.props.pagination.pageCount">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <exportar-modal v-model="showExportarModal" :simulacro-id="simulacroSelectedId"
                    @export="exportSimulacro($event)"></exportar-modal>
  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import ExportarModal from "@/components/dashboardComponents/ExportarModal";
import {downloadCsv} from "../../utils/common";
import { mapState } from "vuex";

export default {
  components: {
    ExportarModal
  },
  data: () => ({
    loading: false,
    page: 1,
    headerArray: [
      {text: 'Nombre', value: 'nombre', align: 'start'},
      {text: 'Respuesta de usuario', value: 'respuestas_usuarios', align: 'center'},
      {text: 'Fecha de apertura', value: 'fecha_apertura', align: 'start'},
      {text: 'Fecha de cierre', value: 'fecha_cierre', align: 'start'},
      {text: 'Duración', value: 'duracion', align: 'center'},
      {text: 'Cantidad de preguntas', value: 'cantidad_preguntas', align: 'center'},
      {text: 'Exportar simulacro', value: 'accion', align: 'center'},
      {text: 'Exportar respuestas por estudiante', value: 'accion-2', align: 'center'}
    ],
    simulacros: [],
    pageSize: 20,
    totalPages: 1,
    totalCounts: 0,
    options: {},
    next: true,
    showExportarModal: false,
    simulacroSelectedId: null
  }),
  async mounted() {
    await this.fetchSimulacros()
  },
  watch: {
    async options() {
      await this.fetchSimulacros()
    },
    async searchGlobal() {
      await this.fetchSimulacros()
    }
  },
  computed: {
    ...mapState(['searchGlobal'])
  },
  methods: {
    async fetchSimulacros() {
      this.loading = true
      let url = `api/admin/simulacro/simulacro/?page=${this.page}&page_size=${this.pageSize}`
      if (this.searchGlobal) { url = `${url}&search=${this.searchGlobal}`}
      const {data} = await axios.get(url)
      this.simulacros = data.results
      this.next = data.next
      this.totalCounts = data.count
      this.loading = false
    },
    async selectSimulacro(simulacroId) {
      this.simulacroSelectedId = simulacroId
      this.showExportarModal = true
    },
    async exportSimulacro({simulacroId, dateFrom, dateTo}) {
      try {
        this.showExportarModal = false
        this.loading = true
        let url = `api/admin/simulacro/simulacro/${simulacroId}/export_csv/?`
        if (dateFrom) {
          url = `${url}&inicio=${dateFrom}`
        }
        if (dateTo) {
          url = `${url}&fin=${dateTo}`
        }

        const response = await axios.get(url)
        downloadCsv(response.data, `Simulacro-${simulacroId}.csv`)
        this.loading = false
      } catch (e) {
        this.loading = false
        await this.$root.$confirm({
          title: '¡Ups!',
          message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
          acceptText: 'Aceptar'
        })
      }
    },
    async exportStudentsAnwerBySimulacro(simulacroId) {
      if (this.loading) return
      try {
        this.loading = true
        const preguntasResponse = await axios.get(`api/admin/simulacro/simulacro/${simulacroId}/all_preguntas/`)
        const simulacroDetallesPreguntas = preguntasResponse.data

        // Headers
        const preguntas = ["Estudiantes"]
        const alternativasCorrectas = ["Correctas"]
        Object.keys(simulacroDetallesPreguntas).map((simulacroDetallePreguntaId) => {
          preguntas.push(simulacroDetallesPreguntas[simulacroDetallePreguntaId].pregunta.descripcion)
          alternativasCorrectas.push(simulacroDetallesPreguntas[simulacroDetallePreguntaId].pregunta.alternativa_correcta)
        })
        let csv = `${preguntas.map(item => JSON.stringify(item)).join(';')}\n${alternativasCorrectas.join(';')}\n`

        // Content
        const historialesResponse = await axios.get(`api/admin/simulacro/simulacro/${simulacroId}/all_historiales/`)
        const historiales = historialesResponse.data
        historiales.map((historial) => {
          const newRow = [historial.email]
          Object.keys(simulacroDetallesPreguntas).map((simulacroDetallePreguntaId) => {
            const index = historial.detalle.findIndex((historialDetalle) => {
              return parseInt(historialDetalle.simulacro_detalle_pregunta) === parseInt(simulacroDetallePreguntaId)
            })
            if (index !== -1) {
              const alternativas = simulacroDetallesPreguntas[simulacroDetallePreguntaId].pregunta.alternativas
              const alternativaContestada = historial.detalle[index].alternativa_contestada
              const letra = this.getLetraAlternativa(alternativas, alternativaContestada)
              newRow.push(letra)
            } else {
              newRow.push('-')
            }
          })
          csv = `${csv}${newRow.map(item => JSON.stringify(item)).join(';')}\n`
        })
        downloadCsv(csv, `Respuestas por usuarios de simulacro-${simulacroId}.csv`)
        this.loading = false
      } catch (e) {
        this.loading = false
        await this.$root.$confirm({
          title: '¡Ups!',
          message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
          acceptText: 'Aceptar'
        })
      }
    },
    getLetraAlternativa(alternativas, alternativaContestada) {
      const letras = 'ABCDEFGHIGKLMNOPQRSTUVWXYZ'
      const index = alternativas.findIndex(item => item.id === alternativaContestada)
      if (index !== -1) {
        return letras.substring(index, index + 1)
      }
      return '-'
    },
    getDateFormat(date) {
      return moment(new Date(date)).format('DD/MM/YYYY')
    },
  }
}
</script>

<style lang="scss" scoped>
.btn {
  border: 1px solid #CED4DA !important;
  text-transform: none !important;
  box-shadow: none !important;
}

.download-button {
  border: 1px solid #CED4DA !important;
  text-transform: none !important;
  box-shadow: none !important;
  color: #009EFB !important;
}
</style>
